body {
  color: white;
}

.homeContainer {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(77, 81, 125);
  background: radial-gradient(circle, rgba(77, 81, 125, 1) 33%, rgba(52, 49, 107, 1) 61%, rgba(46, 61, 80, 1) 88%, rgba(53, 65, 112, 1) 100%);
}

.buttonsContainer {
  margin: 100px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.buttonsContainer button, input {
  padding: 5px 25px;
  margin: 25px;
}

.uploadImageContainer {
  min-height: 350px;
  display: grid;
  place-items: center;
}

.uploadedImages {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.uploadedImages button {
  width: 200px;
}

