.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  background-color: #a4508b;
  background-image: linear-gradient(326deg, #a4508b 10%, #4b0c68 70%);
  font-size: 125%;
  /* background: linear-gradient(225deg,  hsl(329, 70%, 58%), hsl(5, 77%, 71%) 50.91%, hsl(37, 97%, 70%)100%);  */
}

.logoContainer {
  display: grid;
  place-items: center;
  margin-right: 50px;
  border-color: #44002b;
  box-shadow: 0 0.5em 0.5em -0.4em #450028;
  transform: translateY(-0.2em);
}

.welcomeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcomeContainer button {
  padding: 8px 12px;
  margin-left: 50px;
}

.navbar ul {
  display: flex;
  justify-content: space-between;
  min-width: 160px;
  list-style-type: none;
}

.navbar ul li a {
  color: rgb(175, 143, 143);
  text-decoration: none;
  position: relative;
  right: 40px;
  font-family: "Inter";
  font-size: 2rem;
  transition: border-color 0.75s, box-shadow 0.75s, transform 0.75s,
    font-size 0.75s;
}
.navbar ul li a:hover {
  border-color: #44002b;
  color: white;
  box-shadow: 0 0.5em 0.5em -0.4em #450028;
  transform: translateY(-0.45em);
  font-size: 2.2rem;
}
.logo {
  color: beige;
  font-size: 2.7rem;
}
